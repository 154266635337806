import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

// const API_BASE_URL = 'https://172.29.49.138:5000/api'; // Replace with your WSL2 IP address
const API_BASE_URL = 'https://schulzepaul.com/api';
//const API_BASE_URL = 'https://192.168.50.135:5000/api';

// Create AuthContext
const AuthContext = createContext();

// Custom hook to access AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const location = useLocation(); // Get the current route

    useEffect(() => {
        // Only check login status on certain routes
        if (location.pathname === '/admin') {
            const checkLoginStatus = async () => {
                try {
                    const response = await axios.get(`${API_BASE_URL}/check_login`, { withCredentials: true });
                    setIsLoggedIn(response.data.logged_in);
                } catch (error) {
                    console.error('Error checking login status:', error);
                    setIsLoggedIn(false);
                }
            };

            checkLoginStatus();
            window.addEventListener('loginStatusChanged', checkLoginStatus);

            return () => window.removeEventListener('loginStatusChanged', checkLoginStatus);
        }
    }, [location.pathname]); // Depend on the route

    return (
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
            {children}
        </AuthContext.Provider>
    );
};
