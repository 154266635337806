import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './EditProject.css';

// const API_BASE_URL = 'https://172.29.49.138:5000/api'; // Replace with your WSL2 IP address
const API_BASE_URL = 'https://schulzepaul.com/api';
//const API_BASE_URL = 'https://192.168.50.135:5000/api';

const EditProject = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        title: '',
        summary: '',
        description: '',
        technologies: '',
        image_urls: [],
        github_url: '',
        demo_url: ''
    });
    const [newImages, setNewImages] = useState([]); // To store new image files
    const [deletedImages, setDeletedImages] = useState([]); // To store deleted image URLs

    useEffect(() => {
        fetchProject(id);
    }, [id]);

    const fetchProject = (id) => {
        axios.get(`${API_BASE_URL}/projects/${id}`)
            .then(response => {
                console.log('Project fetched successfully:', response.data); // Log fetched project
                setFormData({
                    title: response.data.title,
                    summary: response.data.summary,
                    description: response.data.description,
                    technologies: response.data.technologies,
                    image_urls: response.data.image_urls,
                    github_url: response.data.github_url,
                    demo_url: response.data.demo_url
                });
            })
            .catch(error => {
                console.error('There was an error fetching the project!', error); // Log error
            });
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleFileChange = (e) => {
        setNewImages([...e.target.files]); // Store selected files
    };

    const handleImageDelete = (url) => {
        // Extract the filename from the URL
        const filename = url.split('/').pop();
        setFormData({
            ...formData,
            image_urls: formData.image_urls.filter(img => img !== url)
        });
        setDeletedImages([...deletedImages, filename]); // Track deleted images by filename
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSubmit = new FormData();
    
        // Append the text fields
        Object.keys(formData).forEach(key => {
            if (key !== 'image_urls') {
                formDataToSubmit.append(key, formData[key]);
            }
        });
    
        // Append the new image files
        newImages.forEach(image => {
            formDataToSubmit.append('new_images', image);
        });
    
        // Append the deleted image filenames
        formDataToSubmit.append('deleted_images', deletedImages.join(','));
    
        try {
            const response = await axios.put(`${API_BASE_URL}/projects/${id}`, formDataToSubmit, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            });
    
            console.log('Project updated successfully:', response.data); // Log success response
            navigate(`/projects/${id}`);
        } catch (error) {
            console.error('There was an error updating the project!', error); // Log error
        }
    };

    return (
        <div className="edit-project">
            <h1>Edit Project</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Title:</label>
                    <input type="text" name="title" value={formData.title} onChange={handleChange} />
                </div>
                <div>
                    <label>Summary:</label>
                    <input type="text" name="summary" value={formData.summary} onChange={handleChange} />
                </div>
                <div>
                    <label>Description:</label>
                    <textarea name="description" value={formData.description} onChange={handleChange} />
                </div>
                <div>
                    <label>Technologies:</label>
                    <input type="text" name="technologies" value={formData.technologies} onChange={handleChange} />
                </div>
                <div>
                    <label>Existing Images:</label>
                    {formData.image_urls.map((url, index) => (
                        <div key={index}>
                            <img src={url} alt={`Project ${index}`} style={{ width: '100px' }} />
                            <button type="button" onClick={() => handleImageDelete(url)}>Delete</button>
                        </div>
                    ))}
                </div>
                <div>
                    <label>Add New Images:</label>
                    <input type="file" name="new_images" multiple onChange={handleFileChange} accept="image/*" />
                </div>
                <div>
                    <label>GitHub URL:</label>
                    <input type="text" name="github_url" value={formData.github_url} onChange={handleChange} />
                </div>
                <div>
                    <label>Demo URL:</label>
                    <input type="text" name="demo_url" value={formData.demo_url} onChange={handleChange} />
                </div>
                <button type="submit">Update Project</button>
            </form>
        </div>
    );
};

export default EditProject;
