import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Projects.css';
import LoadingSpinner from '../components/LoadingSpinner'; // Import the loading spinner component

// const API_BASE_URL = 'https://172.29.49.138:5000/api'; // Replace with your WSL2 IP address
const API_BASE_URL = 'https://schulzepaul.com/api';
//const API_BASE_URL = 'https://192.168.50.135:5000/api';

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/projects`);
                setProjects(response.data.projects);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchProjects();
    }, []);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className="projects-container">
            {projects.map(project => (
                <div className="project-thumbnail-container" key={project.id}>
                    <div className="project-thumbnail-content">
                        {project.image_urls.length > 0 ? (
                            <img 
                                src={`https://schulzepaul.com/api/uploads/${project.image_urls[0]}`}
                                alt={project.title}
                                className="project-thumbnail-image"
                            />
                        ) : (
                            <div className="project-thumbnail-fallback">
                                No Image
                            </div>
                        )}
                        <div className="project-thumbnail-title">
                            <Link to={`/projects/${project.id}`}>{project.title}</Link>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Projects;
