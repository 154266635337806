// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Admin from './pages/Admin';
import ProjectDetail from './pages/ProjectDetail';
import EditProject from './pages/EditProject';
import { AuthProvider } from './contexts/AuthProvider';
import OtpVerification from './pages/OtpVerification';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:id" element={<ProjectDetail />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/edit/:id" element={<EditProject />} />
          <Route path="/otp-verification" element={<OtpVerification />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;