import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Home.css'; // Import a CSS file for styling
import FeedbackForm from '../components/FeedbackForm';

// const API_BASE_URL = 'https://172.29.49.138:5000/api'; // Replace with your WSL2 IP address
const API_BASE_URL = 'https://schulzepaul.com/api';
//const API_BASE_URL = 'https://192.168.50.135:5000/api';

const Home = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        axios.get(`${API_BASE_URL}/projects`)
            .then(response => {
                setProjects(response.data.projects.slice(0, 3)); // Display only the first 3 projects
            })
            .catch(error => {
                console.error('There was an error fetching the projects!', error);
            });
    }, []);

    return (
        <div className="home-container">
            <h1>Welcome to My Portfolio</h1>
            <div className="intro-section">
                <h2>About Me</h2>
                <p>
                    I love creating innovative solutions and bringing ideas to life.
                </p>
            </div>
            <div className="projects-section">
                <Link to="/projects" className="view-all-projects">View All Projects</Link>
            </div>
            <FeedbackForm />
        </div>
    );
};

export default Home;
