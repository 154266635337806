import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ImageGallery from 'react-image-gallery';
import './ProjectDetail.css';

// const API_BASE_URL = 'https://172.29.49.138:5000/api'; // Replace with your WSL2 IP address
const API_BASE_URL = 'https://schulzepaul.com/api';
//const API_BASE_URL = 'https://192.168.50.135:5000/api';

const ProjectDetail = () => {
    const { id } = useParams();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/projects/${id}`);
                setProject(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching project:', error);
                setLoading(false);
            }
        };

        fetchProject();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!project) {
        return <div>Project not found.</div>;
    }

    const images = project.image_urls.map(url => ({
        original: `${API_BASE_URL}/uploads/${url}`,
        thumbnail: `${API_BASE_URL}/uploads/${url}` // Adjust if you have separate thumbnail URLs
    }));

    return (
        <div className="project-detail">
            <h1>{project.title}</h1>
            <div className="project-info">
                <p>{project.summary}</p>
                <p><strong>Description:</strong> {project.description}</p>
                <p><strong>Technologies:</strong> {project.technologies}</p>
                {project.github_url && (
                    <p><strong>GitHub URL:</strong> <a href={project.github_url} target="_blank" rel="noopener noreferrer">{project.github_url}</a></p>
                )}
                {project.demo_url && (
                    <p><strong>Demo URL:</strong> <a href={project.demo_url} target="_blank" rel="noopener noreferrer">{project.demo_url}</a></p>
                )}
            </div>
            {images.length > 0 && (
                <div className="image-gallery-container">
                    <ImageGallery items={images} />
                </div>
            )}
        </div>
    );
};

export default ProjectDetail;
