// /home/projects/petProjects/personal-portfolio/frontend/src/pages/OtpVerification.js

//import React, { useState, useEffect } from 'react';
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// const API_BASE_URL = 'https://172.29.49.138:5000/api'; // Replace with your WSL2 IP address
const API_BASE_URL = 'https://schulzepaul.com/api';
//const API_BASE_URL = 'https://192.168.50.135:5000/api';

const OtpVerification = () => {
    //const [qrCode, setQrCode] = useState('');
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();

    /*
    useEffect(() => {
        fetchQrCode();
    }, []);

    const fetchQrCode = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/generate_qr`, { withCredentials: true });
            setQrCode(response.data.qr_code);
        } catch (error) {
            console.error('Failed to fetch QR code', error);
        }
    };
    */

    const handleVerifyOtp = async () => {
        try {
            const response = await axios.post(`${API_BASE_URL}/verify_otp`, { otp }, { withCredentials: true });
            if (response.status === 200) {
                console.log('2FA successful')
                navigate('/admin'); // Redirect to admin panel
            }
        } catch (error) {
            console.error('OTP verification failed', error);
        }
    };
 
    // <img src={`data:image/png;base64,${qrCode}`} alt="QR Code" />

    return (
        <div>
            <h1>OTP Verification</h1>
            
            <input type="text" value={otp} onChange={e => setOtp(e.target.value)} placeholder="Enter OTP" />
            <button onClick={handleVerifyOtp}>Verify OTP</button>
        </div>
    );
};

export default OtpVerification;
